@import 'flag-icons/css/flag-icons.min.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
    --radius: 0.5rem;
    --status-bar-height: 0px;
    --safe-area-top: 0px;
    --safe-area-bottom: 0px;
    --safe-area-left: 0px;
    --safe-area-right: 0px;
    --safe-top-inset: 0px;
  }
}

@layer base {
  * {
    @apply border-gray-200;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.comment-transition {
  transition: all 0.3s ease-in-out;
}

.comment-input {
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.comment-input:focus {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}

.avatar-transition {
  transition: transform 0.2s ease;
}

.avatar-transition:hover {
  transform: scale(1.05);
}

.reply-button {
  transition: all 0.2s ease;
}

.reply-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

body {
  margin: 0 auto; /* Center the body content */
  max-width: 1400px; /* Set the maximum width of the body */
  width: 100%; /* Ensure body takes up full width on smaller screens */
  padding: 0 10px; /* Optional: Add padding to prevent content from touching the edges */
  box-sizing: border-box;
  background-color: rgb(249 250 251) ; /* Replace with your desired color */
  color: rgb(75 85 99);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dark body {
  background-color: #1d2b39;
  color: #ffffff;
}


/* remove arrows from input */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* For Firefox */
}


@keyframes gradient-x {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
.animate-gradient-x {
  animation: gradient-x 5s ease infinite;
  background-size: 200% 200%;
}

/* Glow Border Effect */
.glow-border {
  background-size: 600% 600%;
  animation: glowing 6s ease infinite;
  position: relative;
}

.glow-border::before {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  z-index: -1;
  border-radius: inherit;
  background: inherit;
  filter: blur(10px);
  opacity: 0.8;
  animation: glowing 6s ease infinite;
}

@keyframes glowing {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Pulsating circle */
.pulsating-circle {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
  animation: pulse-ring 2.5s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.pulsating-circle:before {
  content: '';
  position: absolute;
  width: 300%;
  height: 300%;
  border-radius: 50%;
  background-color: rgba(255, 0, 0, 0.5);
  animation: pulse-ring 2.5s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
    opacity: 1;
  }
  100% {
    transform: scale(1.33);
    opacity: 0;
  }
}


/* Floating effect */

@keyframes float {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(10px, -10px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

/* Default state: no animation */
.hover-image {
  transform: translateY(0);
  transition: transform 0.5s ease-in-out; /* Smooth transition back to original position */
}

/* On hover, apply the floating effect */
.card-hover:hover .hover-image {
  transform: translateY(-10px); /* Move image up */
  transition: transform 0.5s ease-in-out; /* Ensure smooth movement when hovering */
}


@keyframes pulse-ring {
  0% {
      transform: scale(.33);
  }
  80%, 100% {
      opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
      transform: scale(.8);
  }
  50% {
      transform: scale(1);
  }
  100% {
      transform: scale(.8);
  }
}

/* Position the pagination at the bottom center */
.swiper-pagination {
  bottom: 10px !important; /* Adjust as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Style the pagination bullets */
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  margin: 0 4px;
  transition: background 0.3s;
}

/* Style the active pagination bullet */
.swiper-pagination-bullet-active {
  background: #ffffff; /* White color for active bullet */
}

/* Optional: Add hover effect */
.swiper-pagination-bullet:hover {
  background: #ffffff;
}

.blinking {
  animation: blink 1s linear infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

/* ScrollingLiveNewsTicker */
.news-ticker {
  overflow: hidden;
  background-color: #333;
  color: #fff;
  font-size: 1em;
  width: 100%;
  position: relative;
}

.news-ticker-content {
  display: inline-block;
  white-space: nowrap;
  animation: scroll 250s linear infinite;
}

.news-item {
  display: inline-block;
  padding: 0 2em;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Responsive Design */
@media (max-width: 600px) {
  .news-item {
    padding: 0 1em;
    font-size: 0.9em;
  }
}

.grecaptcha-badge {
  display: none !important;
}

.small8px-text {
    font-size: 8px;
}

/* Status bar and safe area styles */
.safe-area-wrapper {
  padding-top: var(--safe-top-inset);
  padding-bottom: var(--safe-area-bottom);
  padding-left: var(--safe-area-left);
  padding-right: var(--safe-area-right);
}

/* Ensure navbar respects safe area insets */
#main-nav {
  padding-top: var(--safe-top-inset);
}

.mobile-sidebar {
  padding-top: var(--safe-top-inset);
}
